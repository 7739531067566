import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, finalize, throwError } from 'rxjs';
import { AuthService } from '../_services/authservice';
import { LayoutService } from '../_services/layout.service';


export const jwtInterceptor: HttpInterceptorFn = (authReq, next) => {
  let count = 0;
  const layoutService = inject( LayoutService);
  const authService = inject(AuthService) ;

  layoutService.showSpinner();
  count++;
  const token = authService.getToken();
     authReq = authReq.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        lang:localStorage.getItem('lang') || 'ar'
      }
    });
  
      return next(authReq).pipe(
        catchError((err) => {
          if (err.status === 401) {
           // window.location.href = '/login';
           layoutService.HideSpinner();

          }
          const error = err.error.message || err.statusText;
          return throwError(() => new Error(error.message))
        })
      )
    .pipe(finalize(() => {

      if (count > 0) {
        count--;
      }
      if (count == 0) {
        layoutService.HideSpinner();
      }
    }));
};
