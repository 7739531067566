import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,TranslateModule,DashboardComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  subscription: Subscription = new Subscription();

  title = 'ZIU University';

  constructor(public translateService: TranslateService,
  ) {

   let lang = localStorage.getItem('lang') || 'ar';

   translateService.use(lang=="ar" ? lang : 'en');
   const dom: any = document.querySelector('body');

   if (lang=="ar") {
     dom.classList.add('app_rtl');
   } else {
     dom.classList.remove('app_rtl')


 }


   
 }

}
